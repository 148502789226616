import {
    Abstract
} from './Abstract';

export class ConsignmentNoteVehicleControl extends Abstract {
    constructor(session) {
        super('consignment_note_vehicle_control/ConsignmentNoteVehicleControl', session);
        this.vehicle_config_sat_code = null;
        this.license_plate_vm = null;
        this.anio_vm = null;
        this.civil_insurer = null;
        this.policy_civil_insurer = null;
        this.environment_insurer = null;
        this.policy_environment_insurer = null;
        this.burden_insurer = null;
        this.policy_burden = null;
        this.premium_insurance_value = 0.00;
        this.permit_sct_sat = null;
        this.num_permit_sct = null;
    }
}
